<template>
  <v-card>
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-card-title class="text-no-wrap">
          Trajet
        </v-card-title>
        <v-card-subtitle>Détails sur le trajet</v-card-subtitle>
      </div>

      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="primary"
        >
          {{ icons.mdiRoad }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text>
      <v-list>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiImageText }}
            </v-icon>
            Description:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.description }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCash }}
            </v-icon>
            Prix :
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              color="primary"
              small
            >
              {{ formatAmount(road.global_amount) }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCash }}
            </v-icon>
            Prix hors frais :
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              color="secondary"
              small
            >
              {{ formatAmount(road.amount) }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCash }}
            </v-icon>
            Prix d'annulation:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              color="secondary"
              small
            >
              {{ formatAmount(road.charges_to_cancel) }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiBusArticulatedFront }}
            </v-icon>
            Départ:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.city.name }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiBusArticulatedEnd }}
            </v-icon>
            Arrivé:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.destination.name }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiSeatPassenger }}
            </v-icon>
            Places restantes:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              :color="road.nb_passagers_left > 0 ? 'success' : 'error'"
              small
            >
              {{ road.nb_passagers_left + '/' + road.nb_passagers }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiMapMarkerDown }}
            </v-icon>
            Points de ramassage:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.collection_points }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiMapMarkerUp }}
            </v-icon>
            Points de dépôt:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.drop_off_points }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiClockStart }}
            </v-icon>
            Date et heure de départ:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.start_date ? formattingStartDate(road.start_date) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
            Date de création:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.created_at ? formattingDate(road.created_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
            Date d'annulation:
          </span>
          <v-chip
            v-show="road.cancelled_at !== null"
            color="error"
            small
          >
            <span class="text--secondary font-weight-bold">{{ road.cancelled_at ? formattingDate(road.cancelled_at) : null }}</span>
          </v-chip>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendarEdit }}
            </v-icon>
            Date de mise à jour:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.updated_at ? formattingDate(road.updated_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendarMinus }}
            </v-icon>
            Date de suppression:
          </span>
          <span class="text--secondary font-weight-bold">{{ road.deleted_at ? formattingDate(road.deleted_at) : null }}</span>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiRoad, mdiImageText, mdiCash, mdiClockStart, mdiBusArticulatedFront, mdiBusArticulatedEnd, mdiBadgeAccountAlert,
  mdiSeatPassenger, mdiCashMarker, mdiCalendar, mdiCalendarMinus, mdiCalendarEdit, mdiMapMarkerUp, mdiMapMarkerDown,
} from '@mdi/js'
import { formatDate, formatStartDate } from '@core/date/dateFunctions'
import {intToCurrency} from "@core/dynamicComponents/dynamicComponents";
import store from "@/store";

export default {
  name: 'RoadCard',
  props: {
    road: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formattingDate(date) {
      return formatDate(date)
    },

    formattingStartDate(date) {
      return formatStartDate(date)
    },
  },

  setup() {
    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    return {
      formatAmount,
      icons: {
        mdiRoad,
        mdiImageText,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiMapMarkerDown,
        mdiMapMarkerUp,
        mdiBadgeAccountAlert,
      },
    }
  },
}
</script>

<style scoped>

</style>
